@use "common/variables";
@import "src/common/responsive";

// copied from Login.module.css
.login_form {
  max-width: 500px;
  padding: 30px;
  color: #2b2b2b;

  @include mq("pc") {
    margin: 50px auto;
    border: 1px solid black;
  }

  .note {
    font-size: variables.$font_size_12;
  }
}
