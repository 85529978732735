html,
body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

p {
  margin: 0;
}

.common-button {
  width: 100%;
  height: 48px;
  background-color: #ff8f4f;
  color: black;
  border: none;
  margin: 30px 0;
  border-radius: 20px 20px 20px 20px;
  cursor: pointer;
}
