.title_when_no_other_salon {
  min-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.container {
  margin: 20px 0;

  .trigger {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 10px;

    .icon {
      padding-right: 5px;
    }

    &:hover {
      background-color: #cacaca;
    }
  }

  .content {
    position: fixed;
    border: 1.5px solid #a69f9f;
    border-radius: 5px;
    background-color: white;
    z-index: 200;

    max-height: 60svh;
    overflow: auto;

    .item {
      min-width: 250px;
      padding: 10px;
      display: flex;
      justify-content: left;
    }
    div.item {
      cursor: pointer;
    }

    .item:hover {
      background-color: #f9f9f9;
    }
  }
}
