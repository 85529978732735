@use "common/variables";

.buttons {
  display: flex;
  justify-content: space-between;
  button {
    all: unset;
    border: 1px solid black;
    padding: 3px 5px;
    min-width: 80px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .ok {
    color: variables.$color_refundhub;
    font-weight: bold;
  }
}
