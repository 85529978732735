@use "common/variables" as v;

.reservable_slots {
  position: relative;
  width: 100%;

  .header {
    font-weight: bold;
    margin-bottom: 7px;
  }

  .week_selector {
    display: flex;
    justify-content: space-between;
    border: 0.5px solid #ccc;
    height: 30px;

    .selector {
      background: none;
      border: none;
      font-size: v.$font_size_10;
      cursor: pointer;
    }
  }

  .table_container {
    overflow-x: auto; // 横スクロールを有効化
    -webkit-overflow-scrolling: touch; // iOSでのスムーズスクロール

    .reservable_slots_table {
      width: 100%;

      th,
      td {
        border: 0.5px solid #ccc;
        padding: 5px;
        text-align: center;
        width: calc(100% / 8);
        font-size: v.$font_size_10;
      }

      th {
        font-weight: normal;
      }

      .reservable_slot {
        color: #ff8f4f;
        border: none;
        background: none;
        cursor: pointer;
        width: 100%;
        height: 100%;
      }

      .not_reservable_slot {
      }
    }
  }
}
