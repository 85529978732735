@use "common/variables";

.table_view {
  .table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border-bottom: 1px solid #ccc;
      padding: 12px;
      text-align: center;
    }

    th {
      background-color: #f2f2f2;
      font-size: 13px;
    }

    td {
      .icon {
        color: variables.$color_refundhub;
      }
    }
  }
}
