@use "common/variables";
@import "src/common/responsive";

.sidebar {
  display: flex;
  flex-direction: column;
  width: 250px;
  background-color: #e6e6e6;
  text-align: center;
  /* 親要素がposition fixedのためvh指定 */
  height: 100svh;

  @include mq("mobile") {
    background-color: #cacaca;
  }

  .hamburger_container {
    text-align: right;

    .hamburger {
      margin: 10px 10px 0px 0px;
    }
  }

  .sidebar__logo {
    margin: 30px 0;
    width: 160px;
  }

  .sidebar__index {
    padding: 0;

    .sidebar__index-elm {
      list-style-type: none;
      padding: 7px;
      cursor: pointer;
    }

    .sidebar__new_cancel_button_container {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 35px;
      margin: 8px;
      border-radius: 10px;
      cursor: pointer;
      background-color: #ffad7f;
    }

    .sidebar__active {
      background-color: #f7f5f5;
    }
  }

  .sidebar__footer {
    margin-top: auto;
    border-top: 1px solid #b3a2a2;
    padding: 20px 0;

    .sidebar__logout {
      display: block;
      margin-top: 5px;
      text-decoration: none;
      color: #ff8f4f;
    }
  }
}
