.trigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: 13px;
  line-height: 1;
  gap: 5px;
  border: none;

  text-align: left;
  background-color: #ff8f4f;
  border: 1px solid black;
  border-radius: 10px;
  padding: 6px 10px;
}

.content {
  background-color: #d9d9d9;
  max-width: 500px;
  border: 1px solid black;
  border-radius: 10px;
}

// TODO: もっと細くしたい
.separator {
  height: 1px;
  background-color: black;
  margin: 5px;
}

.item {
  font-size: 13px;
  line-height: 1;
  display: flex;
  align-items: center;

  padding: 6px 10px;
  position: relative;
  border-radius: 10px;
}
