@use "common/variables";

.payment_aborted {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.payment_page {
  max-width: 500px;
  margin: 0 auto;

  // FIXME: 使ってないやつめちゃありそう
  .payment_header {
    text-align: center;
    font-size: variables.$font_size_26;
    font-weight: bold;
    margin: 15px 0;
  }

  .payment_body {
    background-color: #f2f0f0;
    width: 80%;
    margin: 0 auto 10px auto;
    padding: 10px;

    .notes {
      font-size: variables.$font_size_9;
      text-align: center;
    }
  }
  .footer {
    font-size: variables.$font_size_11;
    text-align: center;
  }

  .payment_desc {
    font-size: 10px;
    margin: 5px 0;
  }

  .payment_detail_table {
    width: 100%;
    font-size: 13px;
  }

  .payment_detail_table td {
    border-top: 1px solid #b3a2a2;
    padding: 5px 0;
  }

  .td_left {
    width: 35%;
  }

  .td_right {
    width: 65%;
  }

  .coupon td {
    border-top: initial;
  }

  .section_title {
    margin: 15px 0 7px 0;
  }

  .payment_ul {
    margin: 0;
    font-size: 13px;
  }

  .coupon_detail_switch {
    font-size: 13px;
    width: 100%;
    background-color: white;
    border: 1px solid black;
    border-radius: 9999px;
    text-align: center;
    padding: 7px;
    margin-top: 20px;
    cursor: pointer;
  }

  .payment_method {
    margin-top: 20px;
  }

  .paymant_method_elm {
    font-size: 13px;
  }

  .payment_form {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .payment_label {
    font-size: 10px;
  }

  .payment_input {
    height: 30px;
    padding-left: 7px;
    display: inline-block;
    width: 100%;
    background-color: #e7e7e7;
    border: none;
    margin-bottom: 20px;
  }
  .payment_exp,
  .payment_cvv {
    display: inline-block;
    width: 40%;
    margin: 0;
  }

  .payment_exp {
    margin-right: 10%;
  }

  .payment_cvv {
    margin-left: 10%;
  }

  .payment_button {
    margin: 10px 0;
    height: 35px;
  }

  .payment_bank_text {
    text-align: center;
    font-size: 13px;
    font-weight: bold;
  }

  .email_form input {
    width: 100%;
    height: 26px;
    background-color: #e7e7e7;
    border: none;
  }

  .warning {
    color: variables.$col-form-danger;
    font-size: variables.$font_size_16;
  }
}
