@use "common/variables";

.radio_container {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid variables.$color_gray_1;
  height: 70px;
  cursor: pointer;
  margin-top: 5px;

  .texts {
    margin-left: 10px;
    label {
      font-weight: bold;
    }
    p {
      font-size: variables.$font_size_12;
    }
  }

  input,
  label {
    cursor: pointer;
  }
}
