@use "common/variables";

.loading_overlay {
  position: absolute;
  top: 0;
  left: 0;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  .container {
    background-color: rgba($color: #d9d9d9, $alpha: 0.69);
    position: absolute;
    left: 50%;
    top: 250px;
    transform: translate(-50%, -50%);
    width: 300px;
    border-radius: 30px;
    padding: 20px;

    .icon {
      display: flex;
      justify-content: center;
    }

    .message {
      font-weight: bold;
      margin-top: 20px;
    }
  }
}
