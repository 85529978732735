.main_content {
  max-width: 300px;
  text-align: center;
  margin: 50vh auto 0 auto;
  transform: translateY(-50%);
}

.coupon_text {
  margin: 50px 0 20px 0;
}

.payment_button {
  margin: 40px 0;
  height: 35px;
}
