.trigger {
  cursor: pointer;
}
.dropdown_content {
  border: 0.5px solid #a69f9f;
  border-radius: 5px;
  z-index: 1;
  background-color: white;

  .arrow {
    stroke: #a69f9f;
    fill: white;
  }

  .separator {
    border: 0.1px solid #aaa;
    margin: 0 5px;
  }

  button {
    display: block;
    width: 100%;
    border: none;
    cursor: pointer;
    background-color: white;

    padding: 5px;

    &:hover {
      background-color: #f2f2f2;
    }
  }
}
