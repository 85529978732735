@use "common/variables";

.table_view {
  table {
    th,
    td {
      border-bottom: 1px solid #ccc;
      padding: 10px;
      text-align: center;
    }

    thead {
      th {
        background-color: #f2f2f2;
        font-size: variables.$font_size_11;
      }
    }

    tbody {
      td {
        font-size: variables.$font_size_13;
        color: variables.$color_gray_1;
      }

      td.action_button {
        padding: 10px 0; // override
      }
      .action_button {
        margin: 0 5px;
        background-color: transparent;
        cursor: pointer;
        color: variables.$color_refundhub;
        border: none;
      }
    }

    tbody tr:hover {
      background-color: #f9f9f9;
    }
  }
}
