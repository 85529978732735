.circle {
  width: 100px;
  height: 100px;
  position: relative;
}

.outline {
  stroke-width: 4;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  animation: drawOutline 2s ease forwards;
}

.checkmark {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: drawCheckmark 1s ease 1s forwards;
}

@keyframes drawOutline {
  0% {
    stroke-dashoffset: 650;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheckmark {
  0% {
    stroke-dashoffset: 200;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
