@use "common/variables";

.table_elm_background {
  display: flex;
  justify-content: center;
  padding: 5px;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
}

.background_blue {
  background-color: #a7f8fd;
}

.background_dark_orange {
  background-color: #ff8f4f;
}

.background_gray {
  background-color: #d1d1d1;
}

.background_light_blue {
  background-color: #c7fbfe;
}

.button_active {
  cursor: pointer;
}

.button_inactive {
  cursor: default;
}

.hover_card_content {
  background-color: white;
  padding: 5px;
  border: 1.5px solid #a69f9f;
  border-radius: 6px;
  font-size: variables.$font_size_10;
  z-index: 1000;

  animation-name: slideDownAndFade;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  .arrow {
    stroke: #a69f9f;
    fill: white;
  }
}

@keyframes slideDownAndFade {
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
