@use "common/variables";

.emit_modal_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 7px;
  min-width: 100px;

  color: #ff8f4f;
  background-color: white;
  font-weight: bold;
  border: 1px solid black;
  border-radius: 12px;
  cursor: pointer;

  &:disabled {
    background-color: variables.$color_button_disabled;
  }
}
