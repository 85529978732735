@use "common/variables";
@import "src/common/responsive";

.home {
  @include mq("pc") {
    // TODO: 本当はflex使っていい感じにする方が良さげ
    padding: 0 30px 0 30px;
  }

  .title {
    margin: 0;
    @include mq("mobile") {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: variables.$font_size_22;
      margin: 10px 0 10px 0;
    }

    .reload_button {
      margin-bottom: -5px;
      cursor: pointer;
    }
  }

  .main_top_content {
    margin: 10px 0px;
    @include mq("pc") {
      // NOTE: ここflexつけたら、safariで特定width場合に、謎に全部縦になるバグがでた
      margin-left: 30px;
    }

    .location_reload_button {
      margin-left: auto;
      border: none;
      background: none;
      cursor: pointer;
      color: variables.$color_refundhub;
    }
  }

  .mobile_summary {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .summary {
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin: 0 30px;
    &__item {
      width: 170px;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #a69f9f;

      &_title {
        white-space: nowrap;
        font-size: 12px;
      }

      &_value {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 10px;

        &_amount {
          text-align: left;
          width: 50%;
          box-sizing: border-box;
          color: #ff8f4f;

          .amount_num {
            font-size: variables.$font_size_20;
          }
        }

        &_count {
          text-align: right;
          width: 50%;
          box-sizing: border-box;
          font-size: 12px;
        }
      }
    }
  }

  .main_bottom_content {
    .tabs_container {
      padding: 2px 0 10px 0;

      @include mq("pc") {
        display: flex;
        .update_payment_status_container {
          margin: 0 20px 0 auto;
          display: flex;
          align-items: center;
          .selected_count {
            padding-right: 20px;
          }
          button {
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }

      @include mq("mobile") {
        width: 80%;
        margin: 0 10% 0 10%;
        align-items: center;
      }
      .tab {
        border: none;
        background: none;
        height: 50px;
        cursor: pointer;

        @include mq("pc") {
          min-width: 80px;
        }
        @include mq("mobile") {
          width: calc(100% / 4);
        }

        &:hover {
          @include mq("pc") {
            background-color: #e7e7e7;
          }
        }
        &[data-state="active"] {
          color: #ffad7f;
          border-bottom: 3px solid #ffc19f;
        }
      }

      .encouraging_rereservation_not_scheduled_tab {
        @include mq("mobile") {
          width: calc(100% / 2);
        }
      }
    }
  }
}

.load_modal_overlay {
  position: fixed;
  background-color: rgba($color: #d9d9d9, $alpha: 0.69);
}
