.main_content {
  width: 80%;
  max-width: 400px;
  margin: 30px auto;

  .coupon_ticket {
    border: 1px solid #e1e1e1;
    width: 100%;
    text-align: center;
    color: #ff8f4f;
    font-weight: bold;
    padding: 15px 0;
  }

  .coupon_name {
    font-size: 30px;
  }

  .coupon_text {
    font-size: 15px;
  }

  .phone_image {
    vertical-align: bottom;
    margin-right: 20px;
    margin-bottom: -7px;
  }

  .how_to_use_text {
    font-size: 8px;
    width: 100%;
    text-align: center;
    margin: 7px 0 20px 0;
  }

  .card_title {
    font-size: 12px;
    margin: 0;
  }

  .card {
    width: 100%;
    margin: 5px 0 30px 0;
    padding: 10px;
    background-color: #f2f0f0;
    font-size: 10px;
  }

  .td_left {
    width: 45%;
  }

  .td_right {
    width: 55%;
  }

  .notice_ul {
    padding-left: 10px;
  }

  .coupon_button_div {
    border: 1px solid #e1e1e1;
    width: 100%;
    text-align: center;
    color: #ff8f4f;
    font-weight: bold;
    padding: 15px 5%;
  }

  .coupon_button_div p {
    color: #d5b300;
  }

  .button_text {
    height: 60px;
  }

  .button_text_large {
    font-size: 13px;
  }

  .button_text_small {
    margin-top: 5px;
    font-size: 9px;
  }

  .coupon_button_div button {
    margin: 10px 0;
    height: 30px;
    font-weight: initial;
  }

  .thanks {
    font-size: 15px;
    color: #ff8f4f !important;
  }

  .used_button {
    background-color: #a69f9f;
  }
}
