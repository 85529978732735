@use "common/variables";
@import "src/common/responsive";

.coupon_master {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 0 auto;

  .return {
    margin: 10px auto 10px 10%;
    margin-right: auto;
    cursor: pointer;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 80%;
    h1 {
      margin: 0;
    }
  }

  .form {
    .coupon_master_name {
      margin-bottom: 10px;
      min-height: 30px;
      max-width: 320px;
    }

    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    .warning {
      color: variables.$col-form-warning;
      font-size: variables.$font_size_12;
    }
  }

  .form label {
    display: block;
    width: 100%;
    font-weight: bold;
    margin: 10px 0;
  }

  .radio_label {
    display: inline-block !important;
    width: auto !important;
    font-weight: initial !important;
  }

  .form input[type="text"],
  .form input[type="number"] {
    width: 100%;
    margin: 10px 0;
  }

  .is_default_checkbox_container {
    margin-top: 10px;
  }

  .coupon_button {
    height: 30px;
  }
  .coupon_button:disabled {
    background-color: #d1d1d1 !important;
  }
}
