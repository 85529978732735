@import "src/common/responsive";

.login_form {
  max-width: 500px;
  padding: 30px;
  color: #2b2b2b;

  @include mq("pc") {
    margin: 50px auto;
    border: 1px solid black;
  }
}

.text {
  text-align: center;
  margin-bottom: 40px;
}

.checkbox_container {
  display: flex;
  .checkbox {
    margin: 0;
    height: 20px;
    width: auto;
  }
  margin-bottom: 20px;
}
