@use "common/variables";
@import "src/common/responsive";

.modal_content {
  @include mq("mobile") {
    width: 300px;
  }
  @include mq("pc") {
    width: 400px;
  }
  padding: 25px;

  .form {
    input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid black;
    }

    .warning {
      color: variables.$col-form-warning;
      font-size: variables.$font_size_12;
    }
  }
}
