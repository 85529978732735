@use "common/variables";

.email_form_label {
  font-size: 10px;
  margin: 10px 0 7px 0;
  display: inline-block;

  .required_prefix {
    color: variables.$col-form-danger;
  }
  .required_flag {
    color: variables.$col-form-danger;
  }
}
