.select {
  min-width: 80px;

  :global {
    .rs__control {
      .rs__value-container {
        padding: 2px;
      }
      .rs__indicators {
        .rs__indicator {
          padding: 0;
        }
      }
    }
    .rs__menu {
      position: relative;
      .rs__menu-list {
        .rs__option {
          padding: 4px;
        }
      }
    }
  }
}

.coupon_enabled {
  color: #ffad7f;
  font-weight: bold;
}

.selectable {
  display: inline-block;
  min-width: 55px;
  padding: 3px 15px;
  border-radius: 5px;
  border: 1px solid #666;
  cursor: pointer;
}
