@use "common/variables";

.new_cancel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin: 0 auto;

  .form {
    width: 300px;

    .cancel_type_select,
    .claim_select {
      width: 80%;
      height: 35px;
      cursor: pointer;
    }

    .phone_number_input::-webkit-inner-spin-button,
    .origin_price_input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    .warning {
      color: variables.$col-form-warning;
      font-size: variables.$font_size_12;
    }

    .create_button {
      height: 30px;
      width: 100%;
      cursor: pointer;
    }

    input {
      width: 80%;
    }
  }
}
