@use "common/variables";

.trigger {
  all: unset;

  .icon {
    vertical-align: middle;
    margin: 0 0 2px 2px;
  }
}
.content {
  background-color: white;
  padding: 5px;
  border: 1.5px solid #a69f9f;
  border-radius: 6px;
  font-size: variables.$font_size_12;
  z-index: 1000;

  animation-name: slideDownAndFade;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  .arrow {
    stroke: #a69f9f;
    fill: white;
  }
}

@keyframes slideDownAndFade {
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
