@use "common/variables" as v;

.rereservation_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  width: 80%;
  max-width: 500px;

  .header {
    font-size: v.$font_size_16;
  }

  .customer_name_and_salon_name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 5px;
    border: 1px solid v.$color_gray_1;
    width: 100%;
    font-size: v.$font_size_12;
  }

  .selector {
    margin: 30px 0;
    width: 100%;
    .attribute {
      .attribute_header {
        margin-bottom: 10px;
        font-size: v.$font_size_14;
      }
      margin-bottom: 20px;
    }

    .menu_and_staff_caution {
      font-size: v.$font_size_10;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .cancel_policy_header {
      margin-bottom: 10px;
      .title {
        font-size: v.$font_size_12;
        margin-right: 10px;
      }
      .caution {
        font-size: v.$font_size_10;
      }
    }

    .cancel_policies {
      display: inline-flex;
      flex-direction: column;
      background-color: #e7e7e7;
      border-radius: 15px;
      padding: 3px 15px 3px 10px;
      .cancel_policy_content {
        font-size: v.$font_size_10;
      }
    }
  }

  .slots_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
