@use "common/variables" as v;

.complete_page {
  max-width: 400px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  margin: 0 auto;

  .icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  h2 {
    text-align: center;
    margin: 20px 0;
  }

  .box {
    .box_title {
    }
    .main_content_container {
      margin-top: 10px;
      margin-bottom: 30px;
      padding: 10px;
      background-color: #f2f0f0;

      table {
        tr {
          font-size: v.$font-size-10;
          .title {
            padding-right: 20px;
          }
          .content {
            max-width: 200px;
          }
        }
      }
    }
    .cancel_policy_container {
      margin-top: 10px;
      margin-bottom: 30px;
      padding: 10px;
      background-color: #ff8f4f;

      font-size: v.$font-size-9;
    }
  }
}
