@use "common/variables";

.modal {
  .overlay {
    position: fixed;
    inset: 0;
    background-color: rgba($color: #d9d9d9, $alpha: 0.69);
    width: 100%;
    height: 100%;
    z-index: 300;
  }

  .content {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 302;

    width: 480px;
    max-width: 80%;
    padding: 20px;
    border-radius: 15px;

    background-color: white;

    .loading_icon_container {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      .loading_icon {
        width: 70px;
        animation: rotateAnimation 2s infinite linear; /* 回転アニメーションの設定 */
      }
      @keyframes rotateAnimation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }

    .buttons_container {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      .restart {
        background-color: variables.$color_refundhub;
      }

      button {
        min-width: 80px;
        height: 35px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
}
