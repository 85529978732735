.loading_icon {
  animation: rotateAnimation 2s infinite linear; /* 回転アニメーションの設定 */
  @keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
