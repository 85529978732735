$breakpoints: (
  "mobile": "screen and (max-width: 519px)",
  "pc": "screen and (min-width: 520px)",
) !default;

@mixin mq($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
