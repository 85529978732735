@use "common/variables";

.content {
  h2 {
    margin: 0 0 10px 0;
  }
  .description {
    margin-bottom: 10px;
  }
}
