@use "common/variables";
.table_view {
  .item {
    border: 1.5px solid #a69f9f;
    border-radius: 5px;
    margin-bottom: 30px;
    .attribute {
      width: 80%;
      margin: 0 10%;
      .email {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .buttons {
      width: 80%;
      margin: 0 10%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      button {
        min-height: 30px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: variables.$color_refundhub;
      }
    }
  }
}
