@use "common/variables";
@import "src/common/responsive";

.salon_settings_page {
  max-width: 900px;
  margin: 20px 40px;
  .top_content {
    display: flex;
    align-items: center;

    h1 {
      font-size: variables.$font_size_22;
      margin: 0;
    }
  }

  .main_content {
    .tabs_container {
      display: flex;
      margin-bottom: 30px;

      .tab {
        border: none;
        background: none;
        height: 50px;
        cursor: pointer;

        @include mq("mobile") {
          width: 33%;
        }

        &:hover {
          background-color: #e7e7e7;
        }

        &[data-state="active"] {
          color: #ffad7f;
          border-bottom: 3px solid #ffc19f;
        }
      }
    }
  }
}
