@use "common/variables";

// copied from CancelsTableView.module.scss
// TODO: unnecessary css
.table_container {
  height: calc(
    100svh - 300px
  ); // NOTE: 他のコンポーネントのheightに依存して適切な値は変わるので注意
  overflow: auto;
  font-size: 10px;

  .scroll_table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;

    th,
    td {
      border-bottom: 1px solid #ccc;
      padding: 5px;
      text-align: center;
    }

    th {
      background-color: #f2f2f2;
      font-size: variables.$font_size_11;
    }

    tbody tr:hover {
      background-color: #f9f9f9;
    }

    .cancel_type_select {
      width: auto;
    }

    .editing_name {
      width: 80px;
    }
    .editing_amount {
      width: 65px;
    }
    .editing_phone_number {
      width: 90px;
    }
    input {
      text-align: center;
      height: 25px !important;
    }

    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    select {
      height: 25px;
      width: 45px;
    }

    .table_elm_background {
      display: inline-block; // いらなそう
      padding: 5px;
      width: 70px;
      border-radius: 9999px;
    }
    .background_light_orange {
      background-color: #ffad7f;
    }
    .background_pink {
      background-color: #f9abcc;
    }
    .background_blue {
      background-color: #a7f8fd;
    }
    .background_deep_blue {
      background-color: #7fc7ff;
    }
    .background_gray {
      background-color: #d1d1d1;
    }
    .background_red {
      background-color: #ff4586;
    }
    .background_light_green {
      background-color: #77ecaa;
    }

    .coupon {
      width: 100px;
    }
  }
}

.result {
  margin: 20px 0 0 20px;
  color: #958484;
}

.menu_cell {
  white-space: pre-wrap;
  max-width: 200px;
}
