@use "common/variables";

.mobile_cancels_table_view {
  padding-bottom: 100px; // address barに隠れないように

  .triangle_up_icon {
    position: fixed;
    top: 30px;
    right: 5px;
    z-index: 50;
    width: 60px;
    height: 60px;
    color: #a69f9f;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 10% 15px 10%;

    .cancels_count {
      color: #958484;
    }

    .update_payment_status_container {
      .selected_count {
        margin-right: 10px;
      }
      button {
        border: none;
        border-radius: 5px;
      }
    }
  }

  .item {
    border: 1.5px solid #a69f9f;
    border-radius: 5px;
    width: 80%;
    margin: 0 10% 30px 10%;

    .attribute {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #b3a2a2;
      min-height: 40px;
      padding: 10px 0;
      width: 80%;
      margin: 0 10%;

      .label {
        display: flex;
        justify-content: center;
        min-width: 100px;
        font-size: variables.$font_size_12;
        font-weight: bold;
      }

      .content {
        display: flex;
        justify-content: center;
        width: 130px;
        word-break: break-all;

        // almost copied from CancelsTableView.module.scss
        select {
          height: 23px;
          max-width: 130px;
        }
        input {
          text-align: center;
          height: 23px;
          max-width: 130px;
        }
        // 請求ステータス
        .table_elm_background {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          width: 70px;
          border-radius: 9999px;
          height: 25px;
        }
        .background_light_orange {
          background-color: #ffad7f;
        }
        .background_pink {
          background-color: #f9abcc;
        }
        .background_blue {
          background-color: #a7f8fd;
        }
        .background_gray {
          background-color: #d1d1d1;
        }
        .background_red {
          background-color: #ff4586;
        }
        // 特典
        .coupon_enabled {
          color: #ffad7f;
          font-weight: bold;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .checkbox_edit_container {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .checkbox_container {
          input {
            margin-right: 5px;
          }
        }

        .edit_button {
          display: flex;
          align-items: center;
          height: 30px;
          margin: 0 20px 0 30px;
        }
      }

      .action_button {
        height: 30px;
        width: 80%;
        margin: 0 10px 10px 10px;
      }
    }
  }
}
