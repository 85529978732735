@use "common/variables";

.mobile_summary_item {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 70%;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #b3a2a2;

  .title {
    font-size: variables.$font_size_18;
  }

  .amount_container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 130px;
    color: #ff8f4f;

    .yen {
      font-size: variables.$font_size_18;
    }
    .amount {
      font-size: variables.$font_size_24;
    }
  }
}
