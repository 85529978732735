@use "common/variables";

.content_header {
  margin-bottom: 30px;
  .title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h2 {
      font-size: variables.$font_size_18;
      font-weight: normal;
      margin: 0;
    }
    .emittableModalButton {
    }
  }

  .description {
    color: variables.$color_gray_1;
  }
}
