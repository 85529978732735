@use "common/variables";

.attribute {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b3a2a2;
  height: 40px;
  width: 80%;
  margin: 0 10%;

  .label {
    display: flex;
    justify-content: center;
    min-width: 100px;
    font-size: variables.$font_size_12;
    font-weight: bold;
  }

  .content {
    display: flex;
    justify-content: center;
    min-width: 130px;
  }
}
