@use "common/variables";

.table_view {
  padding-bottom: 100px; // address barに隠れないように

  .count {
    color: #958484;
    margin: 15px 0;
  }

  .item {
    border: 1.5px solid #a69f9f;
    border-radius: 5px;
    width: 94%;
    margin: 0 3% 30px 3%;

    .action_button_container {
      display: flex;
      justify-content: space-around;
      align-items: center;

      height: 40px;
      width: 80%;
      margin: 0 10%;

      .default_coupon_master {
        display: flex;
        align-items: center;
        color: variables.$color_refundhub;
        label {
          font-size: variables.$font_size_11;
        }
      }

      button {
        // TODO:
        border-radius: 5px;
        border: none;
      }
    }
  }
}
