@import "src/common/responsive";

.app {
  // sidebar以外
  .main {
    max-width: 100%;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;

      .hamburger {
        position: fixed;
        left: 10px;
        // margin: 10px 0 0 10px;
      }

      @include mq("mobile") {
        background-color: #e7e7e7;
        width: 100vw;
        .logo {
          height: 30px;
        }
        .location_reload {
          position: fixed;
          right: 10px;
          height: 30px;
        }
      }
    }
  }

  .padding_left_for_sidebar {
    padding-left: 250px;
  }
}

// DialogPortalはappの外にレンダリングされる
.DialogOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba($color: #d9d9d9, $alpha: 0.69);
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
