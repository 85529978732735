@use "common/variables";

.container {
  margin-bottom: 20px;

  .label {
    font-size: 15px;
    padding-bottom: 7px;
  }

  .input {
    width: 100%;
    height: 40px;
    background-color: #e7e7e7;
    border: none;
    padding: 2px;
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .warning {
    color: variables.$col-form-warning;
    font-size: variables.$font_size_12;
  }
}
