$col-form-warning: #ff6e00;
$col-form-danger: #dc3545;
$color_refundhub: #ff8f4f;
$color_button_disabled: #d1d1d1;
$color_gray_1: rgb(64, 64, 64);

$font_size_9: 0.65rem;
$font_size_10: 0.72rem;
$font_size_11: 0.79rem;
$font_size_12: 0.86rem;
$font_size_13: 0.93rem;
$font_size_14: 1rem;
$font_size_15: 1.07rem;
$font_size_16: 1.14rem;
$font_size_17: 1.21rem;
$font_size_18: 1.29rem;
$font_size_20: 1.43rem;
$font_size_22: 1.57rem;
$font_size_23: 1.64rem;
$font_size_24: 1.72rem;
$font_size_26: 1.86rem;
