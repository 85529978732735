@use "common/variables";
@import "src/common/responsive";

.claim_rule {
  padding: 0 30px;

  .top_content {
    display: flex;
    align-items: center;
    margin: 20px 0 0 20px;

    @include mq("pc") {
      .location_reload_button {
        margin-left: auto;
        border: none;
        background: none;
        cursor: pointer;
        color: variables.$color_refundhub;
      }
    }

    @include mq("mobile") {
      justify-content: center;
    }

    h1 {
      font-size: variables.$font_size_22;
      margin: 0;
    }
  }

  .main_content {
    @include mq("pc") {
      max-width: 650px;
    }

    .char_count {
      display: flex;
      justify-content: end;
      font-size: variables.$font_size_12;
      color: #958484;
    }

    textarea {
      margin: 5px 0;
      width: 100%;
      height: calc(100svh - 200px);

      @include mq("mobile") {
        width: 100%;
      }
    }

    .button_container {
      display: flex;
      justify-content: end;

      button {
        height: 35px;
        min-width: 100px;
        border-radius: 30px;
        border: none;
        cursor: pointer;
      }

      .cancel_button {
        background-color: variables.$color_button_disabled;

        @include mq("pc") {
          margin-right: 10px;
        }
        @include mq("mobile") {
          margin-right: auto;
        }
      }

      .main_button {
        background-color: variables.$color_refundhub;
      }
    }
  }
}
