@use "common/variables";
@import "src/common/responsive";

.coupon_masters {
  padding: 0 30px;

  .top_content {
    display: flex;
    align-items: center;
    h1 {
      margin: 0;
    }

    @include mq("pc") {
      margin: 10px 0 0 20px;

      .location_reload_button {
        margin-left: auto;
        border: none;
        background: none;
        cursor: pointer;
        color: variables.$color_refundhub;
      }
    }
    @include mq("mobile") {
      justify-content: center;
    }
  }

  // .switch_container {
  //   display: flex;
  //   align-items: center;
  //   margin-left: auto;

  //   label {
  //     margin-right: 5px;
  //   }
  //   button {
  //     all: unset;
  //   }
  //   .switch_root {
  //     width: 42px;
  //     height: 25px;
  //     border-radius: 9999px;
  //     background-color: black;
  //     position: relative;
  //     -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  //     &[data-state="checked"] {
  //       background-color: black;
  //     }

  //     .switch_thumb {
  //       display: block;
  //       width: 21px;
  //       height: 21px;
  //       background-color: white;
  //       border-radius: 9999px;
  //       transition: transform 100ms;
  //       transform: translateX(2px);
  //       will-change: transform;
  //       &[data-state="checked"] {
  //         transform: translateX(19px);
  //       }
  //     }
  //   }
  // }

  .main_content {
    .tabs_container {
      display: flex;
      padding: 0 0 10px 0;

      .tab {
        border: none;
        background: none;
        height: 50px;
        cursor: pointer;

        @include mq("mobile") {
          width: 33%;
        }

        &:hover {
          background-color: #e7e7e7;
        }

        &[data-state="active"] {
          color: #ffad7f;
          border-bottom: 3px solid #ffc19f;
        }
      }

      .inline_block {
        // 改行候補を指定するため、inline-blockにする
        // https://allabout.co.jp/gm/gc/23789/#google_vignette
        display: inline-block;
      }

      .create_coupon_master_button_container {
        display: flex;
        align-items: center;

        @include mq("pc") {
          width: 130px;
          margin-left: auto;
        }
        @include mq("mobile") {
          width: 33%;
        }

        .create_coupon_master_button {
          color: #ff8f4f;
          font-weight: bold;
          max-height: 50px;
          border: 1px solid black;
          border-radius: 10px;
          cursor: pointer;
          background-color: transparent;

          .text {
            padding-right: 5px;
          }
        }
      }
    }
  }
}
