@use "common/variables";
@import "src/common/responsive";

.attribute {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #b3a2a2;
  min-height: 40px;
  padding: 5px 0px;

  @include mq("mobile") {
    justify-content: space-between;
  }

  .label {
    display: flex;
    font-size: variables.$font_size_12;
    font-weight: bold;
    @include mq("pc") {
      width: 30%;
      justify-content: left;
    }
    @include mq("mobile") {
      justify-content: center;
      min-width: 100px;
    }
  }

  .content {
    display: flex;
    @include mq("pc") {
      width: 70%;
      justify-content: left;
    }
    @include mq("mobile") {
      justify-content: center;
      min-width: 130px;
    }
  }
}
