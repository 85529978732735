@use "common/variables";

.maintenance_page {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
  margin: 0 10%;

  h1 {
    font-size: variables.$font_size_22;
  }
  h2 {
    font-size: variables.$font_size_18;
  }
}
